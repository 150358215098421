import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import BackgroundImage from "../components/utilities/background-image"
import SEO from "../components/utilities/seo"

const ContactPage = () => {
  const data = useStaticQuery(graphql`
    query {
      beach: file(relativePath: { eq: "artwork/Breakwater.jpeg" }) {
        ...galleryImage
      }
    }
  `);
  
  return <Layout>
    <div className='site-background'>
      <BackgroundImage src={ data.beach } />
      <div className='page-wrapper--limited'>
        <SEO title="Contact"/>
        <h1 className='main-title'>Contact</h1>
        <p>Email: <a href='mailto:eleanor.chan@manchester.ac.uk'>eleanor.chan@manchester.ac.uk</a></p>
        <p>Twitter: <a href='https://twitter.com/afalserelation'>@afalserelation</a></p>
      </div>
    </div>
  </Layout>
}

export default ContactPage;
